@import '~antd/dist/antd.css';
@import url(https://fonts.googleapis.com/css?family=Roboto);

strong {
  font-weight: bold;
}

code {
  border-radius: 0.1rem;
  box-shadow: inset 0px 1px 3px 1px rgba(0,0,0,0.15),inset 0px 1px 3px 1px rgba(0,0,0,0.1);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  background: rgba(238,238,255, 0.33) !important;
}

code:hover {
  box-shadow: inset 0px 3px 10px 3px rgba(0,0,0,0.15), inset 0px 1px 6px 3px rgba(0,0,0,0.05);
  background: rgba(250,175,186, 0.33) !important;
}


.ant-layout-header {
  background-color: #76a49a;
}

.foot {
  background-color: #bde5ec;
}

.ant-pagination-simple .ant-pagination-simple-pager input{
  background-color: #bde5ec;
  border: 1px #bde5ec;
  transition: color .3s ease;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover{
  border: 1px #d9d9d9 solid;
  color: #108ee9;
}
.ant-pagination-item, .ant-pagination-prev, .ant-pagination-next {
  background-color: #bde5ec;
  border: 1px solid #d9d9d9;
  color: #fcfcfc;
}

.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.Game-description{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 50%;
  text-align: left;
  overflow: scroll;
  padding: 10px;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}

.Left-sidebar {
  flex: 2;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 10;
  box-shadow: 2px 2px 2px black;
}

.Right-sidebar {
  flex: 3;
  height: 100%;
  align-self: center;
}
