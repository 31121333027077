.bird {
  position: absolute;
  height: 100px;
}


#wing-up {
  display: block; /* reset display from hidden */
  animation-name: flap;
  animation-duration: .25s;
  animation-iteration-count: infinite;
  animation-direction: alternate; /* or: normal */
  animation-timing-function:  linear; /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  animation-fill-mode: forwards; /* or: backwards, both, none */
  animation-delay: .25s; /* or: Xms */
}
#wing-down {
  display: block; /* reset display from hidden */
  animation-name: flap;
  animation-duration: .25s;
  animation-iteration-count: infinite;
  animation-direction: alternate; /* or: normal */
  animation-timing-function: linear; /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  animation-fill-mode: forwards; /* or: backwards, both, none */
  animation-delay: 0s; /* or: Xms */
}


@keyframes flap {
  from {
    fill-opacity: 1;  /* svgs don't support opacticy duh */
  }
  to {
    fill-opacity: 0;
  }
}

.person {
  position: fixed;
  height: 100px;
}

.smooth {
  transition: transform 50ms linear;
  -webkit-transition: transform 50ms linear;
}

.boomerang {
  position: absolute;
  height: 100px;
}

.smooth-rotate {
  transition: transform 50ms linear;
  -webkit-transition: transform 50ms linear;
}

.play {
  height: 100%;
  text-align: left;
  background-image: url('assets/bg_square.svg');
  background-repeat: no-repeat;
  background-size: cover;

}
.preview {
  height: 100%;
  text-align: left;
  background-image: url('assets/bg_square.svg');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
